<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">{{ $t("userMsgs.users") }}</h4>
            </div>
            <!-- <a
              href="#"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#addContact"
              >Add New</a
            > -->
            <router-link :to="{ name: 'add-user' }" class="btn btn-primary">
              {{ $t("userMsgs.addUser") }}
            </router-link>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="mm-search-bar col-md-6 mb-3">
                <div class="searchbox">
                  <a class="search-link" href="#"
                    ><i class="fas fa-search"></i
                  ></a>
                  <input
                    type="text"
                    class="form-control search-input"
                    v-model="searchTerm"
                    :placeholder="`${$t('userMsgs.searchUsers')}...`"
                    @input="onSearch"
                  />
                </div>
              </div>

              <b-table
                :fields="tableColumns"
                :items="allUsers"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
              >
                <template #head(avatar)="">
                  <span></span>
                </template>

                <template #cell(avatar)="data">
                  <b-avatar
                    v-if="data.item.first_name && data.item.last_name"
                    variant="primary"
                    ><span class="text-white">
                      {{
                        `${data.item.first_name[0].toUpperCase()}${data.item.last_name[0].toUpperCase()}`
                      }}</span
                    ></b-avatar
                  >
                  <b-avatar variant="primary" v-else />
                </template>

                <template #cell(name)="data">
                  <strong>
                    {{ data.item.first_name }} {{ data.item.last_name }}</strong
                  >
                </template>

                <template #cell(role)="data">
                  {{ $t(`roleTitles.${data.item.role}`) }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                    :class="
                      'border border-' +
                      (data.item.is_active
                        ? `success text-success`
                        : `danger text-danger`)
                    "
                    variant="none"
                    >{{
                      data.item.is_active
                        ? $t("userMsgs.active")
                        : $t("userMsgs.inactive")
                    }}</b-badge
                  >
                  <!-- <span
                    :class="
                      'badge badge-pill p-2 ' +
                      (data.item.is_active
                        ? 'badge-outline-success'
                        : 'badge-outline-danger')
                    "
                  >
                    {{ data.item.is_active ? $t("userMsgs.active") : $t("userMsgs.inactive") }}
                  </span> -->
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data">
                  <router-link
                    :to="{ name: 'edit-user', params: { id: data.item.id } }"
                    class="svg-icon"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link>
                  <!-- <router-link :to="{ name: 'list-users' }" class="svg-icon">
                    <i class="far fa-trash-alt text-danger" />
                  </router-link> -->

                  <a
                    href="#"
                    @click.prevent="confirmDeleteUser(data.item.id)"
                    v-if="getLoggedInUser.id !== data.item.id"
                    class="ml-2"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                :value="currentPage"
                :total-rows="totalUsers"
                :per-page="pageSize"
                @change="onPageChange"
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { DEFAULT_PAGE_SIZE } from "../../common/constants";
import { formatDateTime } from "../../common/utils";
import { debounce } from "lodash";

export default {
  name: "UserList",

  data() {
    return {
      allUsers: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalUsers: 0,
      isLoading: false,
      ordering: "",
      searchTerm: ""
    };
  },

  methods: {
    ...mapActions(["getAllUsers", "deleteUser"]),
    formatDateTime,

    async fetchAndSetUsers(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllUsers({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allUsers = response.data.results;
      this.currentPage = pageNum;
      this.totalUsers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAndSetUsers(pageNum);
    },

    onSortChange(context) {
      if (context.sortBy === "name") {
        this.ordering = context.sortDesc ? "-first_name,-last_name" : "first_name,last_name"
      } else {
        this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      }
      this.fetchAndSetUsers();
    },

    onSearch() {
      this.debouncedSearchUsers(this);
    },

    debouncedSearchUsers: debounce((vm) => {
      vm.fetchAndSetUsers();
    }, 500),

    async confirmDeleteUser(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("userMsgs.deleteConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteUser(id);
          const refreshPage =
            this.allUsers.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetUsers(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.deletedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchAndSetUsers();
  },

  computed: {
    ...mapGetters(["getLoggedInUser"]),

    tableColumns() {
      return [
        { key: "avatar" },
        { key: "name", label: this.$t("name"), sortable: true },
        { key: "email", label: this.$t("email"), sortable: true },
        { key: "role", label: this.$t("userMsgs.role"), sortable: true },
        { key: "status", label: this.$t("status") },
        {
          key: "date_joined",
          label: this.$t("userMsgs.joinedOn"),
          formatter: (value) => {
            return formatDateTime(value);
          },
          sortable: true
        },
        {
          key: "actions",
          tdClass: "text-right",
          thStyle: { minWidth: "80px" },
        },
      ];
    },
  },
};
</script>
